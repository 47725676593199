/*
 * Theme variables default values
 * Do not override variables in this file, override them in _overrides.scss
 * This file is intended for reference, not modification
 */

/* Grays */
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

/* Colors */
$blue: #467cbf !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

/* Theme Colors */
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

/* Jump point for color jumps */
$theme-color-interval: 8% !default;

/* Other quick global changes */
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

/* Body element */
$body-bg: $gray-100 !default;
$body-color: $gray-900 !default;

/* Borders */
$border-width: 2px !default;
$border-color: $purple !default;
$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

/* Box Shadows */
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

/* Font families */
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji' !default;
// $font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
//   'Liberation
// Mono', 'Courier New', monospace !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

/* Input colors */
$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;
$input-color: $gray-700 !default;
$input-focus-bg: $input-bg !default;
$input-placeholder-color: $gray-600 !default;
$input-color: $gray-700 !default;
$input-border-color: $gray-400 !default;

/* Input group colors */
$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;
$input-plaintext-color: $body-color !default;
$input-focus-color: $input-color !default;
$input-border-color: $gray-400 !default;

/* Dropdown colors */
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, 0.15) !default;
$dropdown-divider-bg: $gray-200 !default;
$dropdown-link-color: $gray-900 !default;
$dropdown-link-hover-color: darken($gray-900, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;
$dropdown-link-disabled-color: $gray-600 !default;
$dropdown-header-color: $gray-600 !default;

/* Typographic Colors */
$headings-color: inherit !default;
$hr-border-color: rgba($black, 0.1) !default;

/* Table colors */
$table-bg: transparent !default;
$table-accent-bg: rgba($black, 0.05) !default;
$table-hover-bg: rgba($black, 0.075) !default;
$table-active-bg: $table-hover-bg !default;
$table-border-color: $gray-300 !default;
$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;
$table-dark-bg: $gray-900 !default;
$table-dark-accent-bg: rgba($white, 0.05) !default;
$table-dark-hover-bg: rgba($white, 0.075) !default;
$table-dark-border-color: lighten($gray-900, 7.5%) !default;
$table-dark-color: $body-bg !default;

/* HPA specific tokens */
$auth-bg: #f5f7fa !default;
$auth-text: $gray-800 !default;
$auth-panel-bg: #fff !default;
$auth-panel-border: #cfdbe2 !default;
$auth-footer-text: inherit !default;
